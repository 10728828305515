<template>
  <el-dialog title="取货单" :visible.sync="dialogVisible" append-to-body custom-class="dialog" :before-close="handleCancel">
    <span slot="footer" class="dialog-footer">
      <div style="float: left">共计 <span class="blue">{{ pickingDetailList.length }}</span><span class="blue">种</span> 货品，数量 <span class="blue">{{ getTotal() }}</span> 件</div>
      <el-button type="primary" @click="handleCancel">关闭</el-button>
    </span>
    <div class="container" v-loading="loading">
      <div class="itemContent">
        <div class="item3" style="margin-right:20px">
          <div class="title">系统单号</div>
          <div class="content">{{picking?.rsys}}</div>
        </div>
        <div class="item4" style="margin-right:20px">
          <div class="title">检索单号</div>
          <div class="content">{{picking?.sretrieval}}</div>
        </div>
        <div class="item5">
          <div class="title">原始单号</div>
          <div class="content">{{picking?.rorder}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">出库门店</div>
          <div class="content">{{picking?.sstore}}</div>
        </div>
        <div class="item2">
          <div class="title">门店地址</div>
          <div class="content">{{picking?.caddr}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item">
          <div class="title">客户地址</div>
          <div class="content">{{picking?.ccustomerAddr}}</div>
        </div>
      </div>
      <div class="itemContent">
        <div class="item1" style="margin-right:20px">
          <div class="title">单据状态</div>
          <div class="content blue">{{pickingStateMap[picking?.zstate]??''}}</div>
        </div>
        <div class="item2">
          <div class="title">单据备注</div>
          <div class="content">{{picking?.cremark}}</div>
        </div>
      </div>
    </div>
    <el-table :data="pickingDetailList" highlight-current-row ref="table" max-height="400" :size="'small'" class="custom-header-table" v-loading="loading">
      <el-table-column label="序号" type="index" align="center" width="40px" :index="getIndex"></el-table-column>
      <el-table-column label="SKU" align="center" width="90px" prop="temp_sku"></el-table-column>
      <el-table-column label="图片" align="center" width="50px" >
        <template #default="scope">
          <img :src="scope.row.c_master_url" width="50px" height="auto"/>
        </template>
      </el-table-column>
      <el-table-column label="货品编码" align="center" width="140px" prop="c_sku"></el-table-column>
      <el-table-column label="货品名称" align="center" width="200px" >
        <template #default="scope">
          <div class="cname">{{ scope.row.c_name }}</div>
        </template>
      </el-table-column>
      <el-table-column label="单位" align="center" width="60px" >
        <template #default="scope">
          {{ scope.row.n_price1 }}
        </template>
      </el-table-column>
      <el-table-column label="售价" align="center" width="70px" prop="n_price" :formatter="priceFormatter"></el-table-column>
      <el-table-column label="库位" align="center" width="90px" prop="c_num"></el-table-column>
      <el-table-column label="数量" align="center" width="60px" prop="num"></el-table-column>
      <el-table-column label="可用库存" align="center" width="60px" >
        <template #default="scope">
          <div class="link">{{ scope.row.n_surplus }}</div>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import {pickingStateMap} from "@/utils/deliveryMap";
import {getOrdPickingDetail,getOrderStoreDetail} from "@/api/delivery";
export default {
  name: "ViewOrdPickingDetail",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    rPicking: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading:false,
      pickingStateMap:pickingStateMap,
      dialogVisible: this.visible,
      picking: {
      },
      pickingDetailList:[],
    };
  },
  created() {

  },
  mounted() {
  },
  methods: {
    async getOrdPickingDetail() {
      if(this.rPicking){
        this.loading = true;
        try {
          let result = await getOrdPickingDetail(this.rPicking);
          if (result && result.data) {
            this.picking = result.data.picking;
            this.pickingDetailList = result.data.pickingDetailList;
            if(this.picking.rstore){
              result = await getOrderStoreDetail({storeId:this.picking.rstore});
              if (result && result.data && result.data.caddr) {
                this.picking['caddr'] = result.data.caddr;
              }
            }
          }
        } finally {
          this.loading = false;
        }
      }
    },
    getIndex(rowIndex) {
      return rowIndex +1;
    },
    getTotal(){
      let totalCount = 0;
      this.pickingDetailList.forEach(item => totalCount += item.num);
      return totalCount;
    },
    priceFormatter(row, column, cellValue) {
      return cellValue?'￥'+cellValue:'';
    },
    handleCancel() {
      this.picking = {};
      this.pickingDetailList = [];
      this.dialogVisible = false;
    },
  },
  watch: {
    visible(newVal) {
      if(newVal){
        this.getOrdPickingDetail();
      }
      this.dialogVisible = newVal;
    },
    dialogVisible(newVal) {
      this.$emit('update:visible', newVal);
    }
  },
};
</script>

<style lang="scss"  scoped>
::v-deep .dialog{
  width: 900px!important;
}
::v-deep .el-dialog__body{
  padding: 10px 20px 30px 20px;
}
::v-deep .el-dialog {
  margin-top: 1vh!important;
}
.cname{
  white-space: normal;
  text-align: left;
}
::v-deep .custom-header-table .el-table__header-wrapper th {
  background-color: rgba(242, 242, 242, 1);
  color: #333;
  font-weight: normal;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.container{
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  color: #000;
  .link{
    color:#409EFF;
    cursor: pointer;
  }
  .blue{
    color:#409EFF;
  }
  .itemContent{
    width: 100%;
    height: auto;
    min-height: 40px;
    overflow: hidden;
    line-height: 40px;
    border-top: 1px solid rgba(220, 223, 230, 1);
    .blue{
      color:#409EFF;
    }
    .item1 {
      width: calc(40% - 12px);
      height: auto;
      min-height: 40px;
      float: left;
      .title {
        width: 80px;
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
      }
      .content {
        width: calc(100% - 80px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color: #909399;
      }
    }
    .item2{
        width: calc(60% - 12px);
        height: auto;
        min-height: 40px;
        float: left;
        .title{
          width: 80px;
          height: auto;
          min-height: 40px;
          line-height: 40px;
          float: left;
          text-align: left;
        }
        .content{
          width: calc(100% - 80px);
          height: auto;
          min-height: 40px;
          line-height: 40px;
          float: left;
          text-align: left;
          color: #909399;
        }
    }
    .item3{
      width: calc(33% - 15px);
      height: auto;
      min-height: 40px;
      float: left;
      .title{
        width: 80px;
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
      }
      .content{
        width: calc(100% - 80px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color: #909399;
      }
    }
    .item4{
      width: calc(23% - 15px);
      height: auto;
      min-height: 40px;
      float: left;
      .title{
        width: 80px;
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
      }
      .content{
        width: calc(100% - 80px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color: #909399;
      }
    }
    .item5{
      width: calc(43% - 15px);
      height: auto;
      min-height: 40px;
      float: left;
      .title{
        width: 80px;
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
      }
      .content{
        width: calc(100% - 80px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color: #909399;
      }
    }
    .item{
      width: 100%;
      height: auto;
      min-height: 40px;
      .title{
        width: 80px;
        height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
      }
      .content{
        width: calc(100% - 100px);
        height: auto;
        min-height: 40px;
        line-height: 40px;
        float: left;
        text-align: left;
        color: #909399;
      }
    }
  }
}
</style>
