var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "取货单",
        visible: _vm.dialogVisible,
        "append-to-body": "",
        "custom-class": "dialog",
        "before-close": _vm.handleCancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("div", { staticStyle: { float: "left" } }, [
            _vm._v("共计 "),
            _c("span", { staticClass: "blue" }, [
              _vm._v(_vm._s(_vm.pickingDetailList.length)),
            ]),
            _c("span", { staticClass: "blue" }, [_vm._v("种")]),
            _vm._v(" 货品，数量 "),
            _c("span", { staticClass: "blue" }, [
              _vm._v(_vm._s(_vm.getTotal())),
            ]),
            _vm._v(" 件"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleCancel } },
            [_vm._v("关闭")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "container",
        },
        [
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item3", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("系统单号")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.picking?.rsys)),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "item4", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("检索单号")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.picking?.sretrieval)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item5" }, [
              _c("div", { staticClass: "title" }, [_vm._v("原始单号")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.picking?.rorder)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("出库门店")]),
                _c("div", { staticClass: "content" }, [
                  _vm._v(_vm._s(_vm.picking?.sstore)),
                ]),
              ]
            ),
            _c("div", { staticClass: "item2" }, [
              _c("div", { staticClass: "title" }, [_vm._v("门店地址")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.picking?.caddr)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c("div", { staticClass: "item" }, [
              _c("div", { staticClass: "title" }, [_vm._v("客户地址")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.picking?.ccustomerAddr)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "itemContent" }, [
            _c(
              "div",
              { staticClass: "item1", staticStyle: { "margin-right": "20px" } },
              [
                _c("div", { staticClass: "title" }, [_vm._v("单据状态")]),
                _c("div", { staticClass: "content blue" }, [
                  _vm._v(
                    _vm._s(_vm.pickingStateMap[_vm.picking?.zstate] ?? "")
                  ),
                ]),
              ]
            ),
            _c("div", { staticClass: "item2" }, [
              _c("div", { staticClass: "title" }, [_vm._v("单据备注")]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.picking?.cremark)),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticClass: "custom-header-table",
          attrs: {
            data: _vm.pickingDetailList,
            "highlight-current-row": "",
            "max-height": "400",
            size: "small",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "序号",
              type: "index",
              align: "center",
              width: "40px",
              index: _vm.getIndex,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "SKU",
              align: "center",
              width: "90px",
              prop: "temp_sku",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "图片", align: "center", width: "50px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("img", {
                      attrs: {
                        src: scope.row.c_master_url,
                        width: "50px",
                        height: "auto",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "货品编码",
              align: "center",
              width: "140px",
              prop: "c_sku",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "货品名称", align: "center", width: "200px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "cname" }, [
                      _vm._v(_vm._s(scope.row.c_name)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "单位", align: "center", width: "60px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" " + _vm._s(scope.row.n_price1) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "售价",
              align: "center",
              width: "70px",
              prop: "n_price",
              formatter: _vm.priceFormatter,
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "库位",
              align: "center",
              width: "90px",
              prop: "c_num",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "数量",
              align: "center",
              width: "60px",
              prop: "num",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "可用库存", align: "center", width: "60px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", { staticClass: "link" }, [
                      _vm._v(_vm._s(scope.row.n_surplus)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }